import ACTION_TYPES from '../../action-types';

export default function currentUser(state = null, action) {
  switch (action.type) {
    case ACTION_TYPES.CURRENT_USER.SUCCESS:
      return action.user;
    case ACTION_TYPES.CURRENT_USER.SIGN_OUT:
      return null;
    default:
      return state;
  }
}
