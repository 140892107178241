import firebase from 'firebase/app';
import ACTION_TYPES from '../action-types';

function setUser(user) {
  return {
    type: ACTION_TYPES.CURRENT_USER.SUCCESS,
    user,
  };
}

function requestUser() {
  return {
    type: ACTION_TYPES.CURRENT_USER.REQUEST,
  };
}

function verifyAuth() {
  return function (dispatch) {
    dispatch(requestUser());

    firebase.auth().onAuthStateChanged((user) => {
      dispatch(setUser(user));
    });
  };
}

function signOutUser() {
  return {
    type: ACTION_TYPES.CURRENT_USER.SIGN_OUT,
  };
}

function signOut() {
  return function (dispatch) {
    firebase
      .auth()
      .signOut()
      .then(
        () => {
          dispatch(signOutUser());
        },
        (error) => {
          alert(error);
        }
      );
  };
}

function signIn(email, password) {
  return function () {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };
}

function signUp(email, password) {
  return function () {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  };
}

function resetPassword(email) {
  return function () {
    return firebase.auth().sendPasswordResetEmail(email);
  };
}

export default {
  verifyAuth,
  signOut,
  signIn,
  signUp,
  resetPassword,
};
