import 'core-js/es/map';
import 'core-js/es/set';

import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';

import { Suspense, lazy } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import './utils/i18n';

import configureStore from './store/configureStore';
import { isMobile } from 'src/utils/ua';

const DesktopApp = lazy(() => import('./desktop/App'));
const MobileApp = lazy(() => import('./mobile/App'));

const store = configureStore();

render(
  <Provider store={store}>
    <Suspense fallback={<div></div>}>{isMobile ? <MobileApp /> : <DesktopApp />}</Suspense>
  </Provider>,
  document.getElementById('root')
);
