import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resources from './resources';

i18next.use(LanguageDetector).init({
  // eslint-disable-next-line no-undef
  debug: process.env.NODE_ENV === 'development',
  resources,
});

export const t = i18next.t.bind(i18next);
export default i18next;
