import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers';
import actions from '../actions';

function configureStore(preloadedState) {
  const composeEnhancers = composeWithDevTools({
    // options like actionSanitizer, stateSanitizer
  });

  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(thunkMiddleware)
      // other store enhancers if any
    )
  );

  // eslint-disable-next-line no-undef
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    // eslint-disable-next-line no-undef
    module.hot.accept('../reducers', () => {
      store.replaceReducer(rootReducer);
    });
  }

  store.dispatch(actions.currentUser.verifyAuth());

  return store;
}

export default configureStore;
