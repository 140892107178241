export default {
  CURRENT_USER: {
    REQUEST: 'CURRENT_USER_REQUEST',
    SUCCESS: 'CURRENT_USER_SUCCESS',
    FAIL: 'CURRENT_USER_FAIL',
    SIGN_OUT: 'CURRENT_USER_SIGN_OUT',
  },
  CALENDAR: {
    CHANGE_MONTH: 'CALENDAR_CHANGE_MONTH',
  },
  DIARIES: {
    REQUEST: 'DIARIES_REQUEST',
    SUCCESS: 'DIARIES_SUCCESS',
    FAIL: 'DIARIES_FAIL',
  },
  DIARY: {
    CHANGE: 'DIARY_CHANGE',
    CREATE: {
      REQUEST: 'DIARY_CREATE_REQUEST',
      SUCCESS: 'DIARY_CREATE_SUCCESS',
      FAIL: 'DIARY_CREATE_FAIL',
    },
    DELETE: {
      REQUEST: 'DIARY_DELETE_REQUEST',
      SUCCESS: 'DIARY_DELETE_SUCCESS',
      FAIL: 'DIARY_DELETE_FAIL',
    },
  },
};
