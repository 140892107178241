import ACTION_TYPES from '../../action-types';

export default function currentUser(
  state = {
    isFetching: false,
  },
  action
) {
  switch (action.type) {
    case ACTION_TYPES.CURRENT_USER.REQUEST:
      return { isFetching: true };
    case ACTION_TYPES.CURRENT_USER.SUCCESS:
      return { isFetching: false };
    default:
      return state;
  }
}
