import { combineReducers } from 'redux';
import calendar from './calendar';
import currentUser from './currentUser';
import diaries from './diaries';

export default combineReducers({
  calendar,
  currentUser,
  diaries,
});
