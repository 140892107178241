import moment from 'moment';
import ACTION_TYPES from '../../action-types';

export default function diaries(
  state = {
    currentDate: moment(),
    lastFetchedDate: null,
    hasMore: true,
    isFetching: false,
    isPosting: false,
  },
  action
) {
  switch (action.type) {
    case ACTION_TYPES.DIARIES.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ACTION_TYPES.DIARIES.SUCCESS: {
      const dates = Object.keys(action.diaries);
      const lastFetchedDate = dates.length > 0 ? moment(dates[0]) : state.lastFetchedDate;
      const hasMore = dates.length >= 40;

      return {
        ...state,
        hasMore,
        lastFetchedDate,
        isFetching: false,
      };
    }
    case ACTION_TYPES.DIARY.CHANGE:
      return {
        ...state,
        currentDate: action.date,
      };
    case ACTION_TYPES.CURRENT_USER.SIGN_OUT: {
      return {
        ...state,
        lastFetchedDate: null,
        hasMore: true,
      };
    }
    default:
      return state;
  }
}
