import ACTION_TYPES from '../action-types';

function changeMonth(date) {
  return {
    type: ACTION_TYPES.CALENDAR.CHANGE_MONTH,
    date,
  };
}

export default {
  changeMonth,
};
