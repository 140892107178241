import { combineReducers } from 'redux';
import ACTION_TYPES from '../../action-types';

function diariesByDate(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.DIARIES.SUCCESS:
      return {
        ...state,
        ...action.diaries,
      };
    case ACTION_TYPES.DIARY.CREATE.SUCCESS:
      return {
        ...state,
        [action.date.format('YYYYMMDD')]: action.diary,
      };
    case ACTION_TYPES.DIARY.DELETE.SUCCESS:
      // eslint-disable-next-line no-case-declarations, no-unused-vars
      const { [action.date.format('YYYYMMDD')]: omit, ...restState } = state;
      return restState;
    case ACTION_TYPES.CURRENT_USER.SIGN_OUT:
      return {};
    default:
      return state;
  }
}

export default combineReducers({
  byDate: diariesByDate,
});
