import firebase from 'firebase/app';
import 'firebase/firestore';
import ACTION_TYPES from '../action-types';
import getDiaryRef from '../utils/getDiaryRef';

function requestDiaries() {
  return {
    type: ACTION_TYPES.DIARIES.REQUEST,
  };
}

function setDiaries(diariesByDate) {
  return {
    type: ACTION_TYPES.DIARIES.SUCCESS,
    diaries: diariesByDate,
  };
}

function fetchDiaries() {
  return function fetchDiariesCreator(dispatch, getState) {
    dispatch(requestDiaries());
    const { lastFetchedDate } = getState().ui.diaries;

    const ref = getDiaryRef(firebase.auth().currentUser.uid);
    let api = firebase.database().ref(ref).orderByKey().limitToLast(40);

    if (lastFetchedDate) {
      api = api.endAt(lastFetchedDate.format('YYYYMMDD'));
    }

    api.once('value').then((snapshot) => {
      dispatch(setDiaries(snapshot.val() || {}));
    });

    // For firestore
    //
    // let api = firebase
    //   .firestore()
    //   .collection('diaries')
    //   .where('uid', '==', firebase.auth().currentUser.uid);

    // if (lastFetchedDate) {
    //   api = api.where('date', '<', lastFetchedDate.format('YYYYMMDD'));
    // }

    // api
    //   .orderBy('date', 'desc')
    //   .limit(40)
    //   .get()
    //   .then((querySnapshot) => {
    //     const diariesByDate = {};
    //     querySnapshot.docs.forEach((queryDocumentSnapshot) => {
    //       const { date, text, images, timestamp } = queryDocumentSnapshot.data();

    //       const obj = { text, timestamp };

    //       if (images) {
    //         obj.images = images;
    //       }

    //       diariesByDate[date] = obj;
    //     });

    //     dispatch(setDiaries(diariesByDate));
    //   });
  };
}

function requestCreateDiary(date, diary) {
  return {
    type: ACTION_TYPES.DIARY.CREATE.REQUEST,
    date,
    diary,
  };
}

function successCreateDiary(date, diary) {
  return {
    type: ACTION_TYPES.DIARY.CREATE.SUCCESS,
    date,
    diary,
  };
}

function failCreateDiary(date, diary) {
  return {
    type: ACTION_TYPES.DIARY.CREATE.FAIL,
    date,
    diary,
  };
}

function createDiary(date, diary) {
  return function (dispatch) {
    dispatch(requestCreateDiary(date, diary));

    const ref = getDiaryRef(firebase.auth().currentUser.uid, date);

    return firebase
      .database()
      .ref(ref)
      .set(diary)
      .then(() => {
        dispatch(successCreateDiary(date, diary));
      })
      .catch(() => {
        dispatch(failCreateDiary(date, diary));
      });
  };
}

function requestDeleteDiary(date) {
  return {
    type: ACTION_TYPES.DIARY.DELETE.REQUEST,
    date,
  };
}

function successDeleteDiary(date) {
  return {
    type: ACTION_TYPES.DIARY.DELETE.SUCCESS,
    date,
  };
}

function failDeleteDiary(date) {
  return {
    type: ACTION_TYPES.DIARY.DELETE.FAIL,
    date,
  };
}

function deleteDiary(date) {
  return function (dispatch) {
    dispatch(requestDeleteDiary(date));
    const ref = getDiaryRef(firebase.auth().currentUser.uid, date);

    return firebase
      .database()
      .ref(ref)
      .remove()
      .then(() => {
        dispatch(successDeleteDiary(date));
      })
      .catch(() => {
        dispatch(failDeleteDiary(date));
      });
  };
}

function changeDiary(date) {
  return {
    type: ACTION_TYPES.DIARY.CHANGE,
    date,
  };
}

export default {
  fetchDiaries,
  changeDiary,
  createDiary,
  deleteDiary,
};
