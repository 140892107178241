import moment from 'moment';
import ACTION_TYPES from '../../action-types';

export default function calendar(
  state = {
    currentMonth: moment(),
  },
  action
) {
  switch (action.type) {
    case ACTION_TYPES.CALENDAR.CHANGE_MONTH:
      return {
        ...state,
        currentMonth: moment(action.date),
      };
    default:
      return state;
  }
}
