import firebase from 'firebase/app';

import currentUser from './currentUser';
import calendar from './calendar';
import diaries from './diaries';

firebase.initializeApp({
  apiKey: 'AIzaSyDjMXNwNegvSzmHGx1x8usm5R9tseW9mBY',
  authDomain: 'diary-a071e.firebaseapp.com',
  databaseURL: 'https://diary-a071e.firebaseio.com',
  projectId: 'diary-a071e',
  storageBucket: 'diary-a071e.appspot.com',
  messagingSenderId: '800196547967',
});

export default {
  currentUser,
  calendar,
  diaries,
};
