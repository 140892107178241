import { Resource } from 'i18next';

/* eslint-disable prettier/prettier */
const easyToControlResources = {
  'intro-1': ['Simply record your day.', '간단히 하루를 기록해 보세요.'],
  'intro-2': [
    'The concept of Simple Diary + Journal is simple. It is easy to use because it has no complicated functions and is made up only of functions that you really need.',
    'Simple Diary + Journal은 간단해요. 복잡한 기능이 없고, 사용하기 쉬운 앱이에요. 오직 필요한 기능으로만 구성했어요.',
  ],
  'sign-up': ['Sign up', '가입하기'],
  'intro-everywhere': ['Everywhere', '다양한 기기 지원'],
  'intro-features': ['Features', '다양한 기능들'],
  'intro-sync': ['Sync', '동기화'],
  'intro-sync-desc-1': [
    'Keep your data safe in the cloud.',
    '데이터를 클라우드 서버에 안전하게 저장하세요.',
  ],
  'checking-sign-in': ['Checking sign in...', '로그인 확인 중...'],
  'pc-web': ['PC web', 'PC 웹'],
  iphone: ['iPhone', '아이폰'],
  android: ['Android', '안드로이드'],
  tablet: ['Tablet (iPad, Android tablet)', '타블렛PC (아이패드, 안드로이드 타블렛)'],
  'multiple-photos': ['Upload multiple photos', '여러장 사진 업로드'],
  calendar: ['Calendar', '달력'],
  timeline: ['Timeline', '타임라인'],
  timestamp: ['Timestamp', '현재 시각 입력'],
  quotes: ['Quotes', '명언들'],
  'auto-save': [
    'Automatic saving (Currently only iOS / Android)',
    '자동 저장 (현재 iOS / Android만 지원)',
  ],
};

const resources: Resource = {
  en: {
    translation: {},
  },
  ko: {
    translation: {},
  },
};

for (const [key, value] of Object.entries(easyToControlResources)) {
  Object.assign(resources.en.translation, { [key]: value[0] });
  Object.assign(resources.ko.translation, { [key]: value[1] });
}

export default resources;
