import moment from 'moment';

const getDiaryRef = function getDiaryRef(userId, date = null) {
  // const ref = `/usertests/${userId}`;
  const ref = `/appusers/${userId}`;

  if (date === null) {
    return ref;
  }

  const formattedDate = moment(date).format('YYYYMMDD');
  return `${ref}/${formattedDate}`;
};

export default getDiaryRef;
